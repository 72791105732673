/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.TeamSection .title {
  font-weight: 600;
  font-size: 4rem;
  color: #745a9e;
  text-align: center;
  padding-top: 5rem;
}
.TeamSection .subtitle {
  font-weight: 600;
  font-size: 1.8rem;
  color: #745a9e;
  text-align: center;
  max-width: 35rem;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 5rem;
}
.TeamSection.board .subtitle,
.TeamSection.board .title {
  color: #fff;
}
.TeamSection.board .jobTitle,
.TeamSection.board .name {
  color: #fff;
}
.TeamSection.board .TeamMember.selected {
  background: #4a82ff;
}
.TeamSection .teamMembers {
  display: flex;
  flex-wrap: wrap;
}
.TeamSection .teamMembers .threeAcross {
  width: 33%;
}
@media (max-width: 767px) {
  .TeamSection .teamMembers .threeAcross {
    width: 50%;
  }
}
.TeamSection .teamMembers .fourAcross {
  width: 25%;
}
@media (max-width: 767px) {
  .TeamSection .teamMembers .fourAcross {
    width: 50%;
  }
}
.TeamMember {
  padding-bottom: 10rem;
}
.TeamMember .headshotFrame img {
  border: 1.2rem solid #3873f7;
}
.TeamMember .headshotFrame.blue .photo {
  border: 1.2rem solid #3873f7;
}
.TeamMember .name {
  color: #fff;
}
.TeamMember.selected {
  background: #4a82ff;
}
.TeamMember .headshotFrame {
  text-align: center;
}
.TeamMember .headshotFrame .photo {
  border-radius: 100%;
  margin: 0 auto;
  width: 18rem;
  height: 18rem;
  border: 1.2rem solid #f9f6ef;
  cursor: pointer;
}
@media (max-width: 767px) {
  .TeamMember .headshotFrame .photo {
    width: 12rem;
    height: 12rem;
    border: 0.8rem solid #f9f6ef;
  }
}
.TeamMember.selected {
  background: #ff486b;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 500;
  overflow: auto;
}
.TeamMember.selected::-webkit-scrollbar {
  display: none;
}
.TeamMember.selected .backgroundImageFrame img {
  width: 100vw;
  height: 100vh;
}
.TeamMember.selected .closeButton img {
  position: absolute;
  top: 10rem;
  right: 10rem;
  z-index: 200;
  width: 8rem;
  height: 8rem;
  cursor: pointer;
}
@media (max-width: 767px) {
  .TeamMember.selected .closeButton img {
    right: 50%;
    margin-right: -4rem;
    margin-top: -5rem;
  }
}
.TeamMember.selected .textSide {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30rem 20rem;
}
@media (max-width: 767px) {
  .TeamMember.selected .textSide {
    padding: 30rem 5rem;
  }
}
.TeamMember.selected .textSide .name {
  font-size: 3rem;
  color: #fff;
  text-align: left;
}
.TeamMember.selected .textSide .jobTitle {
  font-size: 2rem;
  color: #fff;
  text-align: left;
}
.TeamMember.selected .textSide .bio {
  font-size: 2rem;
  color: #fff;
  text-align: left;
  font-weight: 600;
  padding-top: 5rem;
  width: 50%;
}
@media (max-width: 767px) {
  .TeamMember.selected .textSide .bio {
    width: 100%;
    font-size: 1.3rem;
  }
}
.TeamMember.selected .imageSide .backgroundPipe img {
  position: absolute;
  top: 0;
  right: 0;
  height: 105rem;
}
@media (max-width: 767px) {
  .TeamMember.selected .imageSide .backgroundPipe img {
    display: none;
  }
}
.TeamMember.selected .imageSide .headshotFrame.red .photo {
  border: 0.8rem solid #f3365a;
  border-radius: 100%;
}
@media (max-width: 767px) {
  .TeamMember.selected .imageSide .headshotFrame.red .photo {
    width: 12rem;
    height: 12rem;
  }
}
.TeamMember.selected .imageSide .headshotFrame.blue .photo {
  border: 0.8rem solid #3873f7;
  border-radius: 100%;
}
@media (max-width: 767px) {
  .TeamMember.selected .imageSide .headshotFrame.blue .photo {
    width: 12rem;
    height: 12rem;
  }
}
.TeamMember.selected .imageSide .headshotFrame {
  position: absolute;
  top: 34rem;
  right: 22rem;
}
@media (max-width: 767px) {
  .TeamMember.selected .imageSide .headshotFrame {
    right: 50%;
    margin-right: -6rem;
    top: 17rem;
  }
}
.TeamMember.selected .imageSide .headshotFrame .photo {
  width: 25rem;
  height: 25rem;
}
.TeamMember .name {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 2.5rem;
  color: #745a9e;
}
.TeamMember .jobTitle {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  color: #745a9e;
}
