/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.AboutPage {
  background: white;
}
.AboutPage .aboveFold {
  background: #f9f6ef;
  overflow: auto;
  padding: 8rem 8rem 0;
}
@media (max-width: 1199px) {
  .AboutPage .aboveFold {
    padding: 4rem;
  }
}
.AboutPage .aboveFold .cloud1 {
  position: absolute;
  left: 0;
}
.AboutPage .aboveFold .cloud2 {
  position: absolute;
  top: 68rem;
  right: 30rem;
}
@media (max-width: 1199px) {
  .AboutPage .aboveFold .cloud2 {
    display: none;
  }
}
.AboutPage .aboveFold .cloud3 {
  position: absolute;
  top: 60rem;
  right: -1rem;
}
.AboutPage .aboveFold .Image {
  margin-left: 4rem;
}
@media (max-width: 1199px) {
  .AboutPage .aboveFold .Image {
    margin-left: 0;
  }
}
.AboutPage .aboveFold .copy {
  font-weight: 600;
  font-size: 2rem;
  color: #745a9e;
  padding: 2rem 8rem;
  margin-top: 6rem;
}
@media (max-width: 1199px) {
  .AboutPage .aboveFold .copy {
    padding-left: 0;
    padding-right: 0;
    margin-top: 2rem;
    text-align: center;
  }
}
.AboutPage .team {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
.AboutPage .team .Animation {
  position: absolute;
  top: 17rem;
  left: -11rem;
  width: 139rem;
}
@media (max-width: 991px) {
  .AboutPage .team .Animation {
    display: none;
  }
}
.AboutPage .boardMembers {
  background: #4a82ff;
  padding: 8rem;
}
@media (max-width: 767px) {
  .AboutPage .boardMembers {
    padding: 2rem;
  }
}
.AboutPage .sceneSection {
  background: #3873f7;
  margin-bottom: -5px;
}
